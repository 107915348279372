<template>
  <div class="pages">
    <div class="page page1 hidden" id="page1">
      <div class="bg bg1 hidden"></div>
      <div class="bg bg2 hidden"></div>
      <div class="title hidden" id="page1Title">弹指营</div>
      <div class="sub_title hidden" id="page1SubTitle">营销到营收 一站全解决</div>
      <div class="footer hidden" id="page1Footer">灯塔财经信息有限公司</div>
    </div>

    <div class="page page2 hidden" id="page2">
      <div class="bg bg1 hidden"></div>
      <div class="bg bg2 hidden"></div>
      <div class="title hidden" id="page2Title">弹指营</div>
      <div class="sub_title hidden" id="page2SubTitle">一站解决企业营销到营收的难题</div>
      <div class="content hidden" id="page2Content">
        <div class="para">流量贵，获客难</div>
        <div class="para">层层转化，贵上加贵</div>
        <div class="para">平台挤占商户利益空间<br />我们有什么反制手段？</div>
      </div>
    </div>

    <div class="page page3 hidden" id="page3">
      <div class="bg bg1 hidden"></div>
      <div class="bg bg2 hidden"></div>
      <div class="title hidden" id="page3Title">弹指营</div>
      <div class="sub_title hidden" id="page3SubTitle">营销全流程跟踪，高转化击败贵流量</div>
      <div class="content hidden" id="page3Content">
        <div class="para">开源又节流<br />自制内容,营造忠实用户</div>
        <div class="para">内容出发，精简流程<br />监控数据，整合私域生态</div>
        <div class="para">
          多平台内容一键分发<br />多平台统计分析<br />触达用户广<br />目标用户日接触超3次
        </div>
      </div>
    </div>

    <div class="page page4 hidden" id="page4">
      <div class="bg bg1 hidden"></div>
      <div class="title hidden" id="page4Title">产品介绍</div>
      <div class="logo hidden"></div>
      <ul class="product_list hidden" id="productList">
        <li class="li1"><span>内容生成</span></li>
        <li class="li2"><span>一键分发</span></li>
        <li class="li3"><span>线上蓄客</span></li>
        <li class="li4"><span>以老带新</span></li>
        <li class="li5"><span>统计分析</span></li>
        <li class="li6"><span>营收转化</span></li>
      </ul>
      <div class="footer hidden" id="page4Footer">弹指营，营销到营收，一站全解决</div>
      <div class="cursor hidden" id="page4Cursor"></div>
    </div>

    <div class="page page5 hidden" id="page5">
      <div class="bg bg1 hidden"></div>
      <div class="title hidden" id="page5Title">产品介绍</div>
      <div class="sub_title hidden"><div id="page5SubTitle">粉丝互动</div></div>
      <ul class="window hidden">
        <li class="li1 hidden"></li>
        <li class="li2 hidden"></li>
        <li class="li3 hidden"></li>
        <li class="li4 hidden"></li>
        <li class="li5 hidden"></li>
        <li class="li6 hidden"></li>
      </ul>
      <div class="footer hidden" id="page5Footer">弹指营，营销到营收，一站全解决</div>
    </div>

    <div class="page page6 hidden" id="page6">
      <div class="bg bg1 hidden"></div>
      <div class="title hidden" id="page6Title">产品介绍</div>
      <div class="sub_title"><div class="hidden" id="page6SubTitle">线上蓄客</div></div>
      <ul class="video_list hidden">
        <li><span class="video1"> </span><i>自助机扫码</i></li>
        <li><span class="video2"> </span><i>闸机扫码</i></li>
        <li><span class="video3"> </span><i>景区扫码</i></li>
        <li><span class="video4"> </span><i>柜台扫码</i></li>
        <li><span class="video5"> </span><i>付款扫码</i></li>
        <li><span class="video6"> </span><i>物业扫码</i></li>
      </ul>
      <div class="cursor hidden" id="page6Cursor"></div>
      <video :src="video1" ref="refVideo1" id="video1" class="video_center hidden"></video>
      <div class="footer hidden" id="page6Footer">弹指营，营销到营收，一站全解决</div>
    </div>

    <div class="page page7 hidden" id="page7">
      <div class="bg bg1 hidden"></div>
      <div class="title hidden" id="page7Title">产品介绍</div>
      <div class="sub_title"><div class="hidden" id="page7SubTitle">统计分析</div></div>
      <ul class="window hidden">
        <li class="li1 hidden"></li>
        <li class="li2 hidden"></li>
        <li class="li3 hidden"></li>
        <li class="li4 hidden"></li>
        <li class="li5 hidden"></li>
        <li class="li6 hidden"></li>
      </ul>
      <div class="footer hidden" id="page7Footer">弹指营，营销到营收，一站全解决</div>
    </div>

    <div class="page page_video hidden" id="pageVideo">
      <!-- <div class="video_play" @click="playVideo"></div> -->
      <video
        ref="refVideoFinal"
        id="videoFinal"
        class="video_center hidden"
        controls
        preload="auto"
        playsinline
        webkit-playsinline="webkit-playsinline"
      >
        <source src="https://idtcdn.oss-cn-hangzhou.aliyuncs.com/broadcast/oceans.mp4" type="video/mp4" />
      </video>

      <!-- https://idtcdn.oss-cn-hangzhou.aliyuncs.com/broadcast/final.mp4 -->

      <!-- <video
        id="my-player"
        class="video-js"
        controls
        preload="auto"
        poster="//vjs.zencdn.net/v/oceans.png"
      >
        <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4" />
        <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm" />
        <source src="//vjs.zencdn.net/v/oceans.ogv" type="video/ogg" />
      </video> -->
    </div>

    <div class="page page8 hidden" id="page8">
      <div class="bg bg1 hidden"></div>
      <div class="title hidden" id="page8Title">感谢您的观看！</div>
      <div class="sub_title hidden" id="page8SubTitle">点“立即咨询”添加专属营销顾问</div>
      <div class="contact_box hidden">
        <ul class="contact_list">
          <li class=""><span>姓名：</span><b>邓嘉雯</b></li>
          <li class=""><span>职务：</span><b>商务经理</b></li>
          <li class="">
            <span>电话：</span><b><a href="tel:13018050915">13018050915</a></b>
          </li>
        </ul>
        <span class="btn_call hidden" @click="isContactEwmVisible = true">立即咨询</span>
      </div>
      <div
        :class="{ contact_ewm: true, hidden: !isContactEwmVisible }"
        @click="isContactEwmVisible = false"
      >
        <img :src="imgEwm" alt="" @click.stop="isContactEwmVisible = true" /><span
          >微信扫码添加专属营销顾问一对一服务</span
        >
      </div>
      <div class="footer hidden" id="page8Footer">弹指营，营销到营收，一站全解决</div>
    </div>
  </div>
</template>
<script>
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem, randomNum } from "@/utils/utils.js";
import anime from "animejs/lib/anime.es.js";
import axios from "axios";
import $ from "jquery";
import FontFaceObserver from "fontfaceobserver";

import "swiper/css/swiper.min.css";
import Swiper from "swiper";

export default {
  data: function () {
    return {
      isContactEwmVisible: false, //二维码弹框
      video1: require("@/index/views/projects/tanzhiying/videos/video1.mp4"),
      imgEwm: require("@/index/views/projects/tanzhiying/images/contact_ewm.png"),
      videoFinal: "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/broadcast/final.mp4",
      // videoFinal: require("@/index/views/projects/tanzhiying/videos/final.mp4"),
      videoFinalCover: require("@/index/views/projects/tanzhiying/images/contact_ewm.png"),
    };
  },
  components: {},
  methods: {
    async page1In() {
      $("#page1").removeClass("hidden");
      this.bg1 = anyElement({
        targets: document.querySelector("#page1 .bg1"),
        isSplitTxt: false,
        duration: 1500,
        delay: 50,
        easing: "easeOutQuint",
        inEffect: "leftIn",
        outEffect: "leftOut",
      });
      this.bg1.runIn();
      await sleep(800);
      this.bg2 = anyElement({
        targets: document.querySelector("#page1 .bg2"),
        isSplitTxt: false,
        duration: 1500,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "rightIn",
        outEffect: "rightOut",
      });
      this.bg2.runIn();

      await sleep(800);

      $("#page1Title").removeClass("hidden");
      this.page1Title = anyElement({
        targets: document.querySelector("#page1Title"),
        isSplitTxt: true,
        duration: 1200,
        delay: 240,
        easing: "easeOutQuint",
        inEffect: "perspectiveRightIn3",
        outEffect: "fadeOut",
      });
      this.page1Title.runIn();

      await sleep(700);

      $("#page1SubTitle").removeClass("hidden");
      this.page1SubTitle = anyElement({
        targets: document.querySelector("#page1SubTitle"),
        isSplitTxt: true,
        duration: 500,
        delay: 80,
        easing: "easeOutQuint",
        inEffect: "topIn",
        outEffect: "bottomOut",
      });
      this.page1SubTitle.runIn();

      await sleep(500);

      $("#page1Footer").removeClass("hidden");
      this.page1Footer = anyElement({
        targets: document.querySelector("#page1Footer"),
        isSplitTxt: true,
        duration: 500,
        delay: 20,
        easing: "easeOutQuint",
        inEffect: "topIn",
        outEffect: "fadeOut",
      });
      this.page1Footer.runIn();

      await sleep(1500);
      this.page1Out();
    },
    async page1Out() {
      this.bg1.runOut();
      this.bg2.runOut();
      await sleep(100);
      this.page1Title.runOut();
      this.page1SubTitle.runOut();
      this.page1Footer.runOut();
      await sleep(100);
      this.page2In();
    },
    async page2In() {
      $("#page2").removeClass("hidden");
      this.bg1 = anyElement({
        targets: document.querySelector("#page2 .bg1"),
        isSplitTxt: false,
        duration: 1500,
        delay: 50,
        easing: "easeOutQuint",
        inEffect: "leftIn",
        outEffect: "leftOut",
      });
      this.bg1.runIn();
      await sleep(800);
      this.bg2 = anyElement({
        targets: document.querySelector("#page2 .bg2"),
        isSplitTxt: false,
        duration: 1500,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "rightIn",
        outEffect: "rightOut",
      });
      this.bg2.runIn();

      await sleep(200);

      $("#page2Title").removeClass("hidden");
      this.page2Title = anyElement({
        targets: document.querySelector("#page2Title"),
        isSplitTxt: true,
        duration: 600,
        delay: 140,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page2Title.runIn();

      await sleep(200);

      $("#page2SubTitle").removeClass("hidden");
      this.page2SubTitle = anyElement({
        targets: document.querySelector("#page2SubTitle"),
        isSplitTxt: true,
        duration: 300,
        delay: 20,
        easing: "easeOutQuint",
        inEffect: "rightIn",
        outEffect: "bottomOut",
      });
      this.page2SubTitle.runIn();

      await sleep(800);

      $("#page2Content").removeClass("hidden");
      this.page2Content = anyElement({
        targets: document.querySelectorAll("#page2Content div"),
        isSplitTxt: false,
        duration: 1200,
        delay: 550,
        easing: "easeOutQuint",
        inEffect: "topIn",
        outEffect: "bottomOut",
      });
      this.page2Content.runIn();

      await sleep(3500);
      this.page2Out();
    },
    async page2Out() {
      this.bg1.runOut();
      this.bg2.runOut();
      await sleep(100);
      this.page2Title.runOut();
      this.page2SubTitle.runOut();
      this.page2Content.runOut();
      this.page3In();
    },

    async page3In() {
      $("#page3").removeClass("hidden");
      this.bg1 = anyElement({
        targets: document.querySelector("#page3 .bg1"),
        isSplitTxt: false,
        duration: 1500,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "leftIn",
        outEffect: "leftOut",
      });
      this.bg1.runIn();
      await sleep(800);
      this.bg2 = anyElement({
        targets: document.querySelector("#page3 .bg2"),
        isSplitTxt: false,
        duration: 1500,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "rightIn",
        outEffect: "rightOut",
      });
      this.bg2.runIn();

      await sleep(200);

      $("#page3Title").removeClass("hidden");
      this.page3Title = anyElement({
        targets: document.querySelector("#page3Title"),
        isSplitTxt: true,
        duration: 600,
        delay: 140,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page3Title.runIn();

      await sleep(200);

      $("#page3SubTitle").removeClass("hidden");
      this.page3SubTitle = anyElement({
        targets: document.querySelector("#page3SubTitle"),
        isSplitTxt: true,
        duration: 300,
        delay: 20,
        easing: "easeOutQuint",
        inEffect: "rightIn",
        outEffect: "bottomOut",
      });
      this.page3SubTitle.runIn();

      await sleep(800);

      $("#page3Content").removeClass("hidden");
      this.page3Content = anyElement({
        targets: document.querySelectorAll("#page3Content div"),
        isSplitTxt: false,
        duration: 1200,
        delay: 550,
        easing: "easeOutQuint",
        inEffect: "topIn",
        outEffect: "bottomOut",
      });
      this.page3Content.runIn();

      await sleep(3500);
      this.page3Out();
    },
    async page3Out() {
      this.bg1.runOut();
      this.bg2.runOut();
      await sleep(100);
      this.page3Title.runOut();
      this.page3SubTitle.runOut();
      this.page3Content.runOut();
      await sleep(100);
      this.page4In(1);
    },
    async page4In(index) {
      $("#page4").removeClass("hidden");
      this.bg1 = anyElement({
        targets: document.querySelector("#page4 .bg1"),
        isSplitTxt: false,
        duration: index == 1 ? 1500 : 50,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "leftIn",
        outEffect: "leftOut",
      });
      this.bg1.runIn();

      if (index == 1) {
        await sleep(800);
      }

      $("#page4Title").removeClass("hidden");
      this.page4Title = anyElement({
        targets: document.querySelector("#page4Title"),
        isSplitTxt: true,
        duration: index == 1 ? 600 : 50,
        delay: index == 1 ? 140 : 5,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page4Title.runIn();

      if (index == 1) {
        await sleep(800);
      }

      this.page4Logo = anyElement({
        targets: document.querySelector("#page4 .logo"),
        isSplitTxt: false,
        duration: index == 1 ? 900 : 50,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page4Logo.runIn();

      if (index == 1) {
        await sleep(100);
      }

      $("#productList").removeClass("hidden");
      this.page4Product = anyElement({
        targets: document.querySelectorAll("#productList li"),
        isSplitTxt: false,
        duration: index == 1 ? 1000 : 50,
        delay: index == 1 ? 100 : 5,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn2",
        outEffect: "fadeOut",
      });
      this.page4Product.runIn();

      if (index == 1) {
        await sleep(900);
      }

      this.page4Footer = anyElement({
        targets: document.querySelector("#page4Footer"),
        isSplitTxt: false,
        duration: index == 1 ? 700 : 50,
        delay: index == 1 ? 40 : 5,
        easing: "easeOutQuint",
        inEffect: "topIn",
        outEffect: "fadeOut",
      });
      this.page4Footer.runIn();

      await sleep(1500);

      var tl = anime.timeline({
        targets: "#page4Cursor",
        easing: "easeOutExpo", // Can be inherited
        loop: false,
      });
      const positionObj = [
        {
          translateX: 470,
          translateY: 480,
          delay: 0,
          duration: 900,
        },
        {
          translateX: 780,
          translateY: 700,
          delay: 0,
          duration: 900,
        },
        {
          translateX: 780,
          translateY: 1150,
          delay: 0,
          duration: 900,
        },
        {
          translateX: 470,
          translateY: 1350,
          delay: 0,
          duration: 900,
        },
        {
          translateX: 160,
          translateY: 1150,
          delay: 0,
          duration: 900,
        },
        {
          translateX: 150,
          translateY: 680,
          delay: 0,
          duration: 900,
        },
      ];
      tl.add({
        opacity: 1,
        delay: 0,
        translateX: -100,
        translateY: -100,
        duration: 50,
      })
        .add(positionObj[index - 1])
        .add({
          scale: 0.6,
          delay: 50,
          duration: 60,
        })
        .add({
          scale: 1,
          delay: 50,
          duration: 60,
        })
        .add({
          opacity: 0,
          delay: 500,
          duration: 600,
        });
      await sleep(1000);
      this.page4Out(index);
    },
    async page4Out(index) {
      this.bg1.runOut();
      await sleep(100);
      this.page4Title.runOut();
      this.page4Logo.runOut();
      $("#productList").addClass("hidden");
      this.page4Footer.runOut();
      await sleep(3000);
      this.page4Title = null;
      this.page4Logo = null;
      this.page4Product = null;
      this.page4Footer = null;
      if (index < 6) {
        this.page4In(index + 1);
      } else {
        this.page5In();
      }
    },
    async page5In() {
      $("#page5").removeClass("hidden");
      this.bg1 = anyElement({
        targets: document.querySelector("#page5 .bg1"),
        isSplitTxt: false,
        duration: 1500,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "leftIn",
        outEffect: "leftOut",
      });
      this.bg1.runIn();
      await sleep(800);

      $("#page5Title").removeClass("hidden");
      this.page5Title = anyElement({
        targets: document.querySelector("#page5Title"),
        isSplitTxt: true,
        duration: 600,
        delay: 140,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page5Title.runIn();

      await sleep(800);

      $("#page5 .sub_title").removeClass("hidden");
      this.page5SubTitle = anyElement({
        targets: document.querySelector("#page5SubTitle"),
        isSplitTxt: true,
        duration: 900,
        delay: 140,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page5SubTitle.runIn();

      await sleep(800);

      this.page5Footer = anyElement({
        targets: document.querySelector("#page5Footer"),
        isSplitTxt: false,
        duration: 900,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page5Footer.runIn();

      $("#page5 .window").removeClass("hidden");
      $("#page5 .window .li1").removeClass("hidden");
      await sleep(1600);
      $("#page5 .window .li1").addClass("hidden");
      $("#page5 .window .li2").removeClass("hidden");
      await sleep(1600);
      $("#page5 .window .li2").addClass("hidden");
      $("#page5 .window .li3").removeClass("hidden");
      await sleep(1600);
      $("#page5 .window .li3").addClass("hidden");
      $("#page5 .window .li4").removeClass("hidden");
      await sleep(1600);
      $("#page5 .window .li4").addClass("hidden");
      $("#page5 .window .li5").removeClass("hidden");
      await sleep(1600);
      $("#page5 .window .li5").addClass("hidden");
      $("#page5 .window .li6").removeClass("hidden");
      await sleep(1600);
      this.page5Out();
    },
    async page5Out() {
      $("#page5 .sub_title").addClass("hidden");
      $("#page5 .window .li6").addClass("hidden");
      $("#page5 .window").addClass("hidden");
      this.bg1.runOut();
      this.page5Footer.runOut();
      this.page5SubTitle.runOut();
      this.page5Title.runOut();
      await sleep(200);
      this.page6In();
    },
    async page6In() {
      $("#page6").removeClass("hidden");
      this.bg1 = anyElement({
        targets: document.querySelector("#page6 .bg1"),
        isSplitTxt: false,
        duration: 1500,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "leftIn",
        outEffect: "leftOut",
      });
      this.bg1.runIn();
      await sleep(800);

      $("#page6Title").removeClass("hidden");
      this.page6Title = anyElement({
        targets: document.querySelector("#page6Title"),
        isSplitTxt: true,
        duration: 600,
        delay: 140,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page6Title.runIn();

      await sleep(800);

      this.page6SubTitle = anyElement({
        targets: document.querySelector("#page6SubTitle"),
        isSplitTxt: true,
        duration: 900,
        delay: 80,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page6SubTitle.runIn();

      await sleep(800);

      $("#page6 .video_list").removeClass("hidden");
      this.page6VideoList = anyElement({
        targets: document.querySelectorAll("#page6 .video_list li"),
        isSplitTxt: false,
        duration: 500,
        delay: 150,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn2",
        outEffect: "fadeOut",
      });
      this.page6VideoList.runIn();

      await sleep(800);

      this.page6Footer = anyElement({
        targets: document.querySelector("#page6Footer"),
        isSplitTxt: true,
        duration: 900,
        delay: 10,
        easing: "easeOutQuint",
        inEffect: "topIn",
        outEffect: "fadeOut",
      });
      this.page6Footer.runIn();

      await sleep(900);

      var tl = anime.timeline({
        targets: "#page6Cursor",
        easing: "easeOutExpo", // Can be inherited
        loop: false,
      });
      tl.add({
        opacity: 1,
        delay: 0,
        translateX: -100,
        translateY: -100,
        duration: 50,
      })
        .add({
          translateX: 670,
          translateY: 650,
          delay: 0,
          duration: 900,
        })
        .add({
          scale: 0.6,
          delay: 50,
          duration: 60,
        })
        .add({
          scale: 1,
          delay: 50,
          duration: 60,
        })
        .add({
          opacity: 0,
          delay: 500,
          duration: 600,
        });

      await sleep(1500);
      this.page6VideoList.runOut();

      $("#video1").removeClass("hidden");
      await sleep(500);
      this.$refs.refVideo1.play();
      await this.videoEnd("video1");

      this.page6Out();
    },
    videoEnd(videoId) {
      return new Promise((resolve, reject) => {
        document.getElementById(videoId).addEventListener(
          "ended",
          () => {
            resolve();
          },
          false
        );
      });
    },
    async page6Out() {
      this.page6Title.runOut();
      this.page6VideoList.runOut();
      this.page6Footer.runOut();
      $("#video1").addClass("hidden");
      $("#page6SubTitle").addClass("hidden");
      await sleep(400);
      this.page7In();
    },
    async page7In() {
      $("#page7").removeClass("hidden");
      this.bg1 = anyElement({
        targets: document.querySelector("#page7 .bg1"),
        isSplitTxt: false,
        duration: 1500,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "leftIn",
        outEffect: "leftOut",
      });
      this.bg1.runIn();
      await sleep(800);

      $("#page7Title").removeClass("hidden");
      this.page7Title = anyElement({
        targets: document.querySelector("#page7Title"),
        isSplitTxt: true,
        duration: 600,
        delay: 140,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page7Title.runIn();

      await sleep(800);

      $("#page7 .sub_title").removeClass("hidden");
      this.page7SubTitle = anyElement({
        targets: document.querySelector("#page7SubTitle"),
        isSplitTxt: true,
        duration: 900,
        delay: 140,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page7SubTitle.runIn();

      await sleep(800);

      this.page7Footer = anyElement({
        targets: document.querySelector("#page7Footer"),
        isSplitTxt: false,
        duration: 900,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page7Footer.runIn();

      $("#page7 .window").removeClass("hidden");
      $("#page7 .window .li1").removeClass("hidden");
      await sleep(1600);
      $("#page7 .window .li1").addClass("hidden");
      $("#page7 .window .li2").removeClass("hidden");
      await sleep(1600);
      $("#page7 .window .li2").addClass("hidden");
      $("#page7 .window .li3").removeClass("hidden");
      await sleep(1600);
      this.page7Out();
    },
    async page7Out() {
      $("#page7 .window").addClass("hidden");
      this.page7Title.runOut();
      $("#page7 .sub_title").addClass("hidden");
      this.page7SubTitle.runOut();
      this.page7Footer.runOut();
      this.bg1.runOut();
    },
    async page8In() {
      $("#page8").removeClass("hidden");
      this.bg1 = anyElement({
        targets: document.querySelector("#page8 .bg1"),
        isSplitTxt: false,
        duration: 1500,
        delay: 0,
        easing: "easeOutQuint",
        inEffect: "leftIn",
        outEffect: "leftOut",
      });
      this.bg1.runIn();
      await sleep(200);

      $("#page8Title").removeClass("hidden");
      this.page8Title = anyElement({
        targets: document.querySelector("#page8Title"),
        isSplitTxt: true,
        duration: 400,
        delay: 100,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page8Title.runIn();

      await sleep(300);

      $("#page8SubTitle").removeClass("hidden");
      this.page8SubTitle = anyElement({
        targets: document.querySelector("#page8SubTitle"),
        isSplitTxt: true,
        duration: 500,
        delay: 30,
        easing: "easeOutQuint",
        inEffect: "perspectiveScaleIn1",
        outEffect: "fadeOut",
      });
      this.page8SubTitle.runIn();

      await sleep(400);
      $("#page8 .contact_box").removeClass("hidden");
      await sleep(700);

      this.page8ContactList = anyElement({
        targets: document.querySelectorAll("#page8 .contact_list li"),
        isSplitTxt: false,
        duration: 900,
        delay: 140,
        easing: "easeOutQuint",
        inEffect: "topIn",
        outEffect: "fadeOut",
      });
      this.page8ContactList.runIn();

      await sleep(700);

      $("#page8 .btn_call").removeClass("hidden");
    },

    async pageVideoIn() {
      $("#pageVideo").removeClass("hidden");
      $("#videoFinal").removeClass("hidden");
      // await this.videoEnd("videoFinal");
      // this.pageVideoOut();
    },
    async pageVideoOut() {
      $("#pageVideo").addClass("hidden");
      await sleep(500);
      this.page8In();
    },
    // async playVideo() {
    //   $(".video_play").addClass("hidden");
    //   this.$refs.refVideoFinal.play();
    //   await this.videoEnd("videoFinal");
    //   this.pageVideoOut();
    // },

    async fontLoad() {
      const hanBold = new FontFaceObserver("Han Sans Bold");
      const hanHeavy = new FontFaceObserver("Han Serif Heavy");
      const hanRegular = new FontFaceObserver("Han Serif Regular");
      await Promise.all([
        hanBold.load(null, 30 * 1000),
        hanHeavy.load(null, 30 * 1000),
        hanRegular.load(null, 30 * 1000),
      ]);
    },
  },
  async mounted() {
    //每个项目都可以独立设置
    setRem(1080);

    //字体加载比较慢，等它加载完
    await this.fontLoad();

    //开始动画
    this.page1In(1);
    // this.pageVideoIn();
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
